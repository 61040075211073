@font-face {
  font-family: "Amita";
  src: url("./static/fonts/Amita-Bold.ttf") format("truetype");
  src: url("./static/fonts/Amita-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: url("./static/fonts/Poppins-Regular.ttf") format("truetype");
  src: url("./static/fonts/Poppins-SemiBold.ttf") format("truetype");
}

$menu-color: #025db3;

body {
  margin: 0;
  overflow: hidden;
  background-color: black;
}

.icon {
  font-size: large;
  color: white;
  width: min-content;
  padding: 2px 8px;
  margin: 2px;
  border: 3px solid white;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  opacity: 0.3;
  user-select: none;
}

.menu {
  &-container{
    font-family: "Poppins";
    position: absolute;
    z-index: 10;
    @media screen and (min-width: 640px) {
      display: none !important;
    }
  }
  &-icon {
    display: none;
  }
  &-tray {
    display: none;
    color: white;
    height: 100vh;
    width: 80vw;
    overflow: hidden;
    background-color: rgba($color: $menu-color, $alpha: 1.0);
  }
  &-active {
    display: block;
  }
}

.tray {
  &-container{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10;
  }
  &-close {
  }
  &-curve-type{

  }
  &-input-container{
    display: flex;
    flex-direction: column;
    padding: 0 10px 15px 10px;

    & > label {
      margin-bottom: 5px;
    }

    & > button {
      border: 2px solid white;
      border-radius: 4px;
      padding: 10px;
      background-color: transparent;
      color: white;
      font-family: 'Poppins';
      font-size: 16px;

      &:active {
        color: $menu-color;
        border-color: $menu-color;
        background-color: white;
      }
    }
  }
}

.type {
  &-container {
    display: flex;
    gap: 10px;
    width: 100%;
  }
  &-button {
    font-size: large;
    color: white;
    padding: 2px 8px;
    margin: 2px;
    border: 2px solid white;
    border-radius: 4px;
    font-size: 16px;
    background-color: transparent;
    cursor: pointer;
    font-family: 'Poppins';
    font-size: 24px;
    user-select: none;
    flex: 1;

    &:active {
      background-color: white;
      color: $menu-color;
    }

  }

  &-label {
    flex: 10;
    border: 2px solid white;
    border-radius: 4px;
    padding: 10px;
    text-align: center;
  }
}

.card-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 400;
  font-family: "Poppins";
}
.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.title {
  font-weight: bold;
  font-size: 64px;
  font-family: "Amita";
  margin-bottom: 10px;
}
.instructions {
  & > ul {
    text-align: left;
    list-style-type: square;
    & > li {
    }
  }

  @media screen and (max-width: 500px) {
    display: none;
  }
}

.mobile-instructions {
  margin-bottom: 25px;

  @media screen and (min-width: 500px) {
    display: none;
  }
}

.warning {
  font-size: 36px;
  color: red;

  @media screen and (max-width: 500px) {
    font-size: 20px;
    width: 80%;
  }
}
.links {
  padding: 30px 0;
  display: flex;
  width: 100%;
  font-size: 24px;
  justify-content: space-around;
  & > a {
    text-decoration: none;
  }
}
.bottom-text {
  padding: 10px 0;
  font-family: "Courier New", Courier, monospace;
  font-size: 24px;
  color: green;

  @media screen and (max-width: 500px) {
    font-size: 20px;
    padding: 5px 0;
  }

}
.footer-text {
  padding: 10px 0;
  @media screen and (max-width: 500px) {
    font-size: 15px;
    padding: 5px 0;
  }
}
