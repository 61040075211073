@font-face {
  font-family: Amita;
  src: url("Amita-Bold.bb002469.ttf") format("truetype");
  src: url("Amita-Regular.9dfe1efe.ttf") format("truetype");
}

@font-face {
  font-family: Poppins;
  src: url("Poppins-Regular.d5d72893.ttf") format("truetype");
  src: url("Poppins-SemiBold.c0f07c7f.ttf") format("truetype");
}

body {
  background-color: #000;
  margin: 0;
  overflow: hidden;
}

.icon {
  color: #fff;
  width: min-content;
  cursor: pointer;
  opacity: .3;
  -webkit-user-select: none;
  user-select: none;
  border: 3px solid #fff;
  border-radius: 4px;
  margin: 2px;
  padding: 2px 8px;
  font-size: 16px;
}

.menu-container {
  z-index: 10;
  font-family: Poppins;
  position: absolute;
}

@media screen and (min-width: 640px) {
  .menu-container {
    display: none !important;
  }
}

.menu-icon {
  display: none;
}

.menu-tray {
  color: #fff;
  height: 100vh;
  width: 80vw;
  background-color: #025db3;
  display: none;
  overflow: hidden;
}

.menu-active {
  display: block;
}

.tray-container {
  flex-flow: column wrap;
  gap: 10px;
  display: flex;
}

.tray-input-container {
  flex-direction: column;
  padding: 0 10px 15px;
  display: flex;
}

.tray-input-container > label {
  margin-bottom: 5px;
}

.tray-input-container > button {
  color: #fff;
  background-color: #0000;
  border: 2px solid #fff;
  border-radius: 4px;
  padding: 10px;
  font-family: Poppins;
  font-size: 16px;
}

.tray-input-container > button:active {
  color: #025db3;
  background-color: #fff;
  border-color: #025db3;
}

.type-container {
  width: 100%;
  gap: 10px;
  display: flex;
}

.type-button {
  color: #fff;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: #0000;
  border: 2px solid #fff;
  border-radius: 4px;
  flex: 1;
  margin: 2px;
  padding: 2px 8px;
  font-family: Poppins;
  font-size: 24px;
}

.type-button:active {
  color: #025db3;
  background-color: #fff;
}

.type-label {
  text-align: center;
  border: 2px solid #fff;
  border-radius: 4px;
  flex: 10;
  padding: 10px;
}

.card-container {
  width: 100%;
  height: 100vh;
  color: #fff;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  font-weight: 400;
  display: flex;
}

.card-content {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.title {
  margin-bottom: 10px;
  font-family: Amita;
  font-size: 64px;
  font-weight: bold;
}

.instructions > ul {
  text-align: left;
  list-style-type: square;
}

@media screen and (max-width: 500px) {
  .instructions {
    display: none;
  }
}

.mobile-instructions {
  margin-bottom: 25px;
}

@media screen and (min-width: 500px) {
  .mobile-instructions {
    display: none;
  }
}

.warning {
  color: red;
  font-size: 36px;
}

@media screen and (max-width: 500px) {
  .warning {
    width: 80%;
    font-size: 20px;
  }
}

.links {
  width: 100%;
  justify-content: space-around;
  padding: 30px 0;
  font-size: 24px;
  display: flex;
}

.links > a {
  text-decoration: none;
}

.bottom-text {
  color: green;
  padding: 10px 0;
  font-family: Courier New, Courier, monospace;
  font-size: 24px;
}

@media screen and (max-width: 500px) {
  .bottom-text {
    padding: 5px 0;
    font-size: 20px;
  }
}

.footer-text {
  padding: 10px 0;
}

@media screen and (max-width: 500px) {
  .footer-text {
    padding: 5px 0;
    font-size: 15px;
  }
}

/*# sourceMappingURL=index.6ca20abb.css.map */
